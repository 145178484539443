import Vue from "vue";
import Vuex from "vuex";

import createPersistedState from "vuex-persistedstate";
import Cookies from "js-cookie";
Vue.use(Vuex);

export default new Vuex.Store({
  // plugins: [
  //   createPersistedState({
  //     storage: window.localStorage,
  //     reducer: (state) => {
  //       return {
  //         userInfo: state.userInfo,
  //       };
  //     },
  //   }),
  // ],
  state: {
    userInfo: {},
    token: "",
    updateTime: ''
  },
  getters: {},
  mutations: {
    UPDATAE_ACCOUNT(state, val) {
      state.userInfo = val;
    },
    UPDATE_TIME(state, val) {
      state.updateTime = val
    }
  },
  actions: {},
  modules: {},
});
