<template>
  <div class="layout">
    <div class="container">
      <TopNavBar />
      <slot />
    </div>
  </div>
</template>

<script>
import TopNavBar from "@/components/TopNavbar";
export default {
  components: {
    TopNavBar,
  },
};
</script>

<style lang="scss" scoped>
.layout {
  max-width: 1920px;
  background-color: #f2f3f7;
  margin: 0 auto;
  .container {
    width: 1300px;
    margin: auto;
    min-height: 100vh;
  }
}
</style>