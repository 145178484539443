<template>
  <div class="login-form">
    <div class="login-text">
      System Login
    </div>
    <el-form
      ref="ruleForm"
      label-position="top"
      :model="ruleForm"
      :rules="rules"
      style="padding-top: 40px; width: 280px; margin: 0 auto"
    >
      <el-form-item
        prop="username"
        style="border-radius: 50px"
      >
        <el-input
          v-model.trim="ruleForm.username"
          placeholder="Username"
          class="input-username"
        />
      </el-form-item>
      <el-form-item prop="password">
        <el-input
          ref="pass" 
          v-model.trim="ruleForm.password"
          type="password"
          placeholder="Password"
          class="input-password"
          show-password
        >
          <div
            slot="suffix"
            class="suffix"
            @click="togglePass"
          >
            <svg
              v-if="passIcon"
              viewBox="64 64 896 896"
              focusable="false"
              data-icon="eye"
              width="1em"
              height="1em"
              fill="currentColor"
              aria-hidden="true"
            ><path d="M942.2 486.2C847.4 286.5 704.1 186 512 186c-192.2 0-335.4 100.5-430.2 300.3a60.3 60.3 0 000 51.5C176.6 737.5 319.9 838 512 838c192.2 0 335.4-100.5 430.2-300.3 7.7-16.2 7.7-35 0-51.5zM512 766c-161.3 0-279.4-81.8-362.7-254C232.6 339.8 350.7 258 512 258c161.3 0 279.4 81.8 362.7 254C791.5 684.2 673.4 766 512 766zm-4-430c-97.2 0-176 78.8-176 176s78.8 176 176 176 176-78.8 176-176-78.8-176-176-176zm0 288c-61.9 0-112-50.1-112-112s50.1-112 112-112 112 50.1 112 112-50.1 112-112 112z" /></svg>
            <svg
              v-else
              viewBox="64 64 896 896"
              focusable="false"
              data-icon="eye-invisible"
              width="1em"
              height="1em"
              fill="currentColor"
              aria-hidden="true"
            ><path d="M942.2 486.2Q889.47 375.11 816.7 305l-50.88 50.88C807.31 395.53 843.45 447.4 874.7 512 791.5 684.2 673.4 766 512 766q-72.67 0-133.87-22.38L323 798.75Q408 838 512 838q288.3 0 430.2-300.3a60.29 60.29 0 000-51.5zm-63.57-320.64L836 122.88a8 8 0 00-11.32 0L715.31 232.2Q624.86 186 512 186q-288.3 0-430.2 300.3a60.3 60.3 0 000 51.5q56.69 119.4 136.5 191.41L112.48 835a8 8 0 000 11.31L155.17 889a8 8 0 0011.31 0l712.15-712.12a8 8 0 000-11.32zM149.3 512C232.6 339.8 350.7 258 512 258c54.54 0 104.13 9.36 149.12 28.39l-70.3 70.3a176 176 0 00-238.13 238.13l-83.42 83.42C223.1 637.49 183.3 582.28 149.3 512zm246.7 0a112.11 112.11 0 01146.2-106.69L401.31 546.2A112 112 0 01396 512z" /><path d="M508 624c-3.46 0-6.87-.16-10.25-.47l-52.82 52.82a176.09 176.09 0 00227.42-227.42l-52.82 52.82c.31 3.38.47 6.79.47 10.25a111.94 111.94 0 01-112 112z" /></svg>
          </div>
        </el-input>
      </el-form-item>
      <el-form-item>
        <el-button
          class="input-login"
          @click="submitForm('ruleForm')"
        >
          Login
        </el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { Login } from "@/api/user";
import { Message } from "element-ui";
import Cookies from "js-cookie";
export default {
  name: "LoginForm",
  data() {
    return {
      ruleForm: {
        username: "",
        password: "",
      },
      rules: {
        username: [
          {
            required: true,
            message: "Please input  username",
            trigger: "blur",
          },
          {
            min: 3,
            max: 15,
            message: "Length should be 3 to 15",
            trigger: "blur",
          },
        ],
        password: [
          {
            required: true,
            message: "Please input  password",
            trigger: "blur",
          },
          {
            min: 6,
            max: 18,
            message: "6 to 18 characters in length",
            trigger: "blur",
          },
        ],
      },
      passIcon: false
    };
  },
  methods: {
    /**
     * 这里使用 element-ui 的内部属性，可能会随版本变动而发生改变
     * passwordVisible
     * 使用 Autocomplete Slots suffix 对图标做自定义
     * 隐藏掉原始的 el-icon-view 图标
     */
    togglePass() {
      const flag = !this.$refs.pass.passwordVisible
      this.$refs.pass.passwordVisible = flag
      this.passIcon = flag
    },  
    submitForm(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          let params = {
            account: this.ruleForm.username,
            password: this.ruleForm.password,
          };
          let { ok, data } = await Login(params);
          if (ok) {
            debugger
            Message.success("Login succeeded.");
            Cookies.set("token", data.token, { expires: 1 });
            this.$store.state.userInfo = data;
            // this.$store.commit("UPDATAE_ACCOUNT", data);
            window.localStorage.setItem("userInfo", JSON.stringify(data));
            this.$router.push("/");
          }
        } else {
          Message.error("Information verification failed.");
          return false;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.login-text {
  margin-top: 100px;
  text-align: center;
  font-size: 20px;
  color: rgb(214, 0, 16);
  font-family: "zihun143hao";
  font-weight: bold;
}
.input-login,
.input-username,
.input-password {
  width: 280px;
  height: 40px;
}
.input-login {
  margin-top: 10px;
  border-radius: 30px;
  background-color: #d60010;
  color: #fff;
}
::v-deep .el-input__inner {
  border-radius: 30px;
}

.suffix {
  margin-right: 10px;
  margin-top: 2px;
  cursor: pointer;
  user-select: none;
}

.login-form::v-deep .el-icon-view {
  display: none;
}
</style>

<style>
.el-message {
  margin-top: 70px !important;
}
</style>
