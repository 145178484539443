import { get, post } from "@/utils/request";

// 1. 用户登录

async function Login(data) {
  return await post("oem/login", data);
}

// 2. 修改密码

async function updatePassword(data) {
  return await post("oem/updatePwd", data);
}

// 3. 获取用户资料

async function getUserInfo() {
  return await get("oem/info");
}

// 4. 修改用户信息

async function modifyAccount(data) {
  return await post("oem/updateInfo", data);
}
export { Login, updatePassword, getUserInfo, modifyAccount };
