import { http } from "./http";
const get = (url, params, config = {}) => {
  return new Promise((resolve, reject) => {
    http.request({
      method: "get",
      url,
      params,
      ...config,
    })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const post = (url, data, config = {}) => {
  return new Promise((resolve, reject) => {
    http({
      method: "post",
      url,
      data,
      ...config,
    })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export { get, post };
