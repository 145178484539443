function judgmentMobile() {
  var sUserAgent = navigator.userAgent.toLowerCase();
  var bIsIpad = sUserAgent.match(/ipad/i) == "ipad";
  var bIsIphoneOs = sUserAgent.match(/iphone os/i) == "iphone os";
  var bIsMidp = sUserAgent.match(/midp/i) == "midp";
  var bIsUc7 = sUserAgent.match(/rv:1.2.3.4/i) == "rv:1.2.3.4";
  var bIsUc = sUserAgent.match(/ucweb/i) == "ucweb";
  var bIsAndroid = sUserAgent.match(/android/i) == "android";
  var bIsCE = sUserAgent.match(/windows ce/i) == "windows ce";
  var bIsWM = sUserAgent.match(/windows mobile/i) == "windows mobile";
  if (
    bIsIpad ||
    bIsIphoneOs ||
    bIsMidp ||
    bIsUc7 ||
    bIsUc ||
    bIsAndroid ||
    bIsCE ||
    bIsWM
  ) {
    //跳转移动端页面
    return true;
  }
}

// 判断两个对象的属性和值 是否相等
function objectEqual(a, b) {
  if (Object.keys(a).length !== Object.keys(b).length) {
    return false;
  }
  for (let key in a) {
    if (a[key] !== b[key]) {
      return false;
    }
  }
  return true;
}
// 节流函数
function _throttle(func, time) {
  let oldTime = Date.now();
  return function () {
    const _this = this;
    let args = arguments;
    let newTime = Date.now();
    if (newTime - oldTime >= time) {
      func.apply(_this, args);
      oldTime = newTime;
    }
  };
}
// 防抖函数
function _debounce(func, time) {
  let timer;
  return function () {
    const _this = this;
    const args = arguments;
    timer = setTimeout(() => {
      clearTimeout(timer);
      func.apply(_this, args);
    }, time);
  };
}
export { judgmentMobile, objectEqual, _throttle, _debounce };
