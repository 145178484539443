import { get } from "@/utils/request";

function getUnDeliveryList(data) {
  return get("customer/undelivery", data);
}

function getUserGroupClient(data) {
  return get("group/client", data)
}

function getContainerPackingList(data) {
  return get("ship-plan/container-packingList", data)
}

function getShipPlanList(data) {
  return get("ship-plan/shipPlanPart-list", data)
}

function searchClient(data) {
  return get("/v1/search/clients", data)
}

export {
  getUnDeliveryList,
  getUserGroupClient,
  getContainerPackingList,
  getShipPlanList,
  searchClient
};