<template>
  <HomeLayout>
    <div class="un-delivery">
      <PullOrDown style="margin-bottom: 40px">
        <el-form
          ref="ruleForm"
          :model="formData"
          :rules="rules"
          label-width="115px"
          label-position="left"
          style="padding: 20px 19px 10px 20px"
        >
          <div class="form-item-wrap">
            <el-form-item
              label="Class:"
              prop="groupSelected"
            >
              <el-select
                v-if="group.length"
                v-model="formData.groupSelected"
                style="width: 265px"
                @change="handleGroupChange"
              >
                <el-option
                  v-for="item in group"
                  :key="item.id"
                  :value="item.id"
                  :label="item.label"
                />
              </el-select>
              <span v-else>{{ clientInfo.group }}</span>
            </el-form-item>
            <el-form-item
              label="Client:"
              prop="clientSelected"
            >
              <ClientSearch
                v-if="group.length"
                ref="clientsearch"
                :init-value="formData.clientSelected"
                :init-options="clientList"
                :style-str="'width: 265px;'"
                @valueChange="handleClientSearch"
              />
              <span v-else>{{ clientInfo.client }}</span>
            </el-form-item>
            <el-form-item style="width: 400px" />
          </div>
          <div class="form-item-wrap">
            <el-form-item
              label="Client Part No.:"
              prop="clientPartNo"
            >
              <el-input
                v-model="formData.clientPartNo"
                style="width: 265px"
                placeholder="please enter"
                clearable
              />
            </el-form-item>
            <el-form-item
              label="EP Part No.:"
              prop="epPartNo"
            >
              <el-input
                v-model="formData.epPartNo"
                style="width: 265px"
                placeholder="please enter"
                clearable
              />
            </el-form-item>
            <el-form-item
              label="PO#:"
              label-width="50px"
              style="width: 400px"
              prop="po"
            >
              <el-input
                v-model="formData.po"
                style="width: 265px"
                placeholder="please enter"
                clearable
              />
            </el-form-item>
          </div>
          <div class="form-item-wrap">
            <el-form-item
              label="From:"
              prop="fromDate"
            >
              <el-date-picker
                v-model="formData.fromDate"
                style="width: 265px"
                type="date"
                placeholder="select Date"
                value-format="yyyy-MM-dd"
                clearable
              />
            </el-form-item>
            <el-form-item
              label="To:"
              prop="toDate"
            >
              <el-date-picker
                v-model="formData.toDate"
                style="width: 265px"
                type="date"
                placeholder="select Date"
                value-format="yyyy-MM-dd"
                clearable
              />
            </el-form-item>
            <el-form-item
              label="From:"
              label-width="50px"
              style="width: 400px"
              prop="from"
            >
              <el-select
                v-model="formData.from"
                style="width: 265px"
              >
                <el-option
                  :value="1"
                  label="All"
                />
                <el-option
                  :value="2"
                  label="Transit warehouse actual"
                />
                <el-option
                  :value="3"
                  label="Transit warehouse virtual"
                />
                <el-option
                  :value="4"
                  label="Transit warehouse none"
                />
              </el-select>
            </el-form-item>
          </div>
          <div style="margin-bottom: 8px">
            <el-checkbox
              v-model="formData.onlyShowStock"
              style="width:400px"
            >
              Only show stock
            </el-checkbox>
            <el-checkbox v-model="formData.show3Weeks">
              Show 3+weeks
            </el-checkbox>
          </div>
          <div style="margin-top: 8px;">
            <el-button
              type="primary"
              @click="submitForm('ruleForm')"
            >
              Inquiry
            </el-button>
            <el-button
              style="margin-left:30px"
              @click="resetForm('ruleForm')"
            >
              Clear
            </el-button>

            <el-button
              style="float: right;margin-right: 90px;"
              @click="exportData"
            >
              Export Data On This Page
            </el-button>
          </div>
        </el-form>
      </PullOrDown>
      <div
        v-loading="loading"
        class="inquiry-result"
      >
        <div class="inquiry-title">
          <div class="title-client">
            <span
              class="curosr"
              @click="toggleHeaderSearch('client')"
            >Client</span>
          </div>
          <div class="title-vender">
            <span
              class="curosr"
              @click="toggleHeaderSearch('venderPn')"
            >Vender P/N</span>
          </div>
          <div class="title-clientpn">
            <span
              class="curosr"
              @click="toggleHeaderSearch('clientPn')"
            >Client P/N</span>
          </div>
          <div class="title-po">
            <span
              class="curosr"
              @click="toggleHeaderSearch('po')"
            >PO#</span>
          </div>
          <div class="title-due-date">
            <span
              class="curosr"
              @click="toggleHeaderSearch('dueDate')"
            >Due Date</span>
          </div>
          <div class="title-bal">
            Bal./Order
          </div>
          <div class="title-from">
            From
          </div>
          <div class="title-sr">
            Stock/Remain
          </div>
          <div class="title-in">
            IN 1-15D
          </div>
          <div class="title-d16-30">
            16-30D
          </div>
          <div class="title-d30">
            30D
          </div>
          <div class="title-mark">
            Mark
          </div>
          <div class="title-lastInvoice">
            Last invoiced
          </div>
        </div>
        <div
          v-for="(item, index) in list"
          :key="item.id"
          class="inquiry-item"
          :class="{ odd: index % 2 !== 0 }"
        >
          <div class="item-client">
            <span
              style="cursor: pointer;"
              @click="handleClickClientCodeSearch(item)"
            >{{ item.client }}</span>
          </div>
          <div
            class="item-vender"
          >
            <span 
              style="cursor: pointer;text-decoration: underline;"
              @click="navToDetailReport(item)"
            >
              {{ item.venderPn }}
            </span>
          </div>
          <div
            class="item-clientpn"
          >
            <span
              style="cursor: pointer;text-decoration: underline;"
              @click="navToDetailReport(item)"
            >
              {{ item.clientPn }}
            </span>
          </div>
          <div class="item-po">
            {{ item.po }}
          </div>
          <div class="item-due-date">
            {{ item.dueDate }}
          </div>
          <div class="item-bal">
            {{ item.balOrder }}
          </div>
          <div class="item-from">
            {{ item.from }}
          </div>
          <div class="item-sr">
            {{ item.stockRemain }}
          </div>
          <div class="item-in">
            {{ item.in115D }}
          </div>
          <div class="item-d16-30">
            {{ item.in1630D }}
          </div>
          <div class="item-d30">
            {{ item.in30D }}
          </div>
          <div class="item-mark">
            <el-tooltip
              popper-class="tooltip-width"
              effect="dark"
              :content="item.mark"
              placement="top-end"
            >
              <span>{{ item.mark }}</span>
            </el-tooltip>
          </div>
          <div>
            {{ item.lastInvoiced }}
          </div>
        </div>
        <div style="text-align: center; padding-top: 30px">
          <el-pagination
            background
            :page-sizes="[10, 20, 50, 100, 500]"
            layout="total, sizes, prev, pager, next, jumper"
            :total="totalPage"
            :page-size.sync="pageSize"
            :current-page.sync="page"
            @current-change="getUnDeliveryList"
            @size-change="getUnDeliveryList"
          />
        </div>
      </div>
    </div>
    <el-backtop />
  </HomeLayout>
</template>
  
<script>
import { getUnDeliveryList, getUserGroupClient } from "@/api/index";
import PullOrDown from "@/components/PullOrDown.vue";
import HomeLayout from "@/layout/HomeLayout.vue";
import XLSX from 'xlsx'
import ClientSearch from "@/components/ClientSearch.vue";
import { searchClient } from '@/api/index'
export default {
  components: {
    PullOrDown,
    HomeLayout,
    ClientSearch
  },
  data() {
    return {
      page: 1,
      pageSize: 10,
      total: {},
      totalPage: 0,
      list: [],
      clientInfo: "",
      group: [],
      clientList: [],
      formData: {
        groupSelected: null,
        clientSelected: null,
        clientPartNo: "",
        epPartNo: "",
        po: "",
        fromDate: "",
        toDate: "",
        from: 1,
        onlyShowStock: false,
        show3Weeks: true,
      },
      rules: {},
      loading: true,
      sortField: "",
      sortDirect: 0,
    };
  },
  async created() {
    await this.getUserGroupClient();
    this.getUnDeliveryList();
  },
  methods: {
    toggleHeaderSearch(field) {
      const asFn = (a, b) => {
        const as = String(a[field]== undefined ? '' : a[field])
        const bs = String(b[field] == undefined ? '' : b[field])
        if (as > bs) {
          return 1;
        } else if (as === bs) {
          return 0;
        } else {
          return -1;
        }
      };

      const dsFn = (a, b) => {
        const as = String(a[field]== undefined ? '' : a[field])
        const bs = String(b[field] == undefined ? '' : b[field])
        console.log("a, b", as, bs)
        if (as > bs) {
          return -1;
        } else if (as === bs) {
          return 0;
        } else {
          return 1;
        }
      };

      if (field === this.sortField) {
        if (this.sortDirect === 1) {
          this.list.sort(asFn);
          this.sortDirect = 0;
        } else {
          this.list.sort(dsFn);
          this.sortDirect = 1;
        }
      } else {
        this.sortField = field;
        this.list.sort(asFn);
        this.sortDirect = 0;
      }
    },
    async handleClickClientCodeSearch({ client }) {
      const { ok, data } = await searchClient({ keyword: client })
      if (ok) {
        for (let i = 0; i < data.length; i++) {
          if (data[i].label === client) {
            this.$refs['clientsearch'].options = data
            this.$refs['clientsearch'].value = data[i].id
            this.formData.clientSelected = data[i].id
            this.$nextTick(() => {
              this.submitForm('ruleForm')
            })
            return
          }
        }
        Message.warning("Client code search fail!")
      }
    },
    navToDetailReport(item) {
      const args = item.id.split('_')
      const params = {
        multipleSelection: args[2] + '_' + args[3],
        group: this.formData.groupSelected,
        client: this.$refs['clientsearch'].options,
        clentid: this.$refs['clientsearch'].value
      }
      const routeData = this.$router.resolve({
        name: "detailReport",
        query: {
          state: 1
        }
      });
      localStorage.setItem("detailReportArgs", JSON.stringify(params))
      window.open(routeData.href, '_blank');
    },
    exportData() {
      if(!this.list.length) {
        this.$message.warning("please inquiry")
        return
      }
      const wb = XLSX.utils.book_new()
      const tableHeader = [
      'Client','Vender P/N','Client P/N','PO#','Due Date','Bal./order','From','Stock/Remain','In 1-15D','16-30D', '30D', 'mark', 'Last invoiced']
      const data = [tableHeader]
      const orderStr = ['client', 'venderPn', 'clientPn', 'po', 'dueDate', 'balOrder', 'from', 'stockRemain', 'in115D', 'in1630D', 'in30D', 'mark', "lastInvoiced"]
      this.list.forEach(ele => {
        const tempArr = []
        orderStr.forEach(str => {
          tempArr.push(ele[str])
        })
        data.push(tempArr)
      })
      
      const ws = XLSX.utils.aoa_to_sheet(data)

      XLSX.utils.book_append_sheet(wb, ws, 'Sheet1')
      const dateString = Date.now()

      XLSX.writeFile(wb, `${dateString}.xlsx`, { compression: true});
    },

    handleGroupChange(e) {
      this.group.forEach((ele) => {
        if (ele.id === e) {
          this.formData.clientSelected = null;
          this.clientList = ele.client;
        }
      });
    },

    async getUnDeliveryList() {
      const args = Object.assign({}, this.formData);
      if (!this.group.length) {
        const { clientId } = this.clientInfo;
        args.clientId = clientId;
      } else {
        args.clientId = args.clientSelected;
        args.classId = args.groupSelected;
        delete args.clientSelected;
        delete args.groupSelected;
      }
      const { pageSize, page } = this;
      args.page = page;
      args.pagesize = pageSize;
      args.dateFrom = args.fromDate;
      args.dateTo = args.toDate;
      delete args.fromDate;
      delete args.toDate;
      const { ok, data } = await getUnDeliveryList(args);
      if (ok) {
        this.$store.state.updateTime = data.updateTime
        // eslint-disable-next-line
        const { listDetail, totalPages } = data;
        this.list = listDetail;
        this.totalPage = totalPages;
        this.loading = false
      }
    },

    async getUserGroupClient() {
      const { ok, data } = await getUserGroupClient();
      if (ok) {
        const { group, clientInfo } = data;
        this.clientInfo = clientInfo;
        this.group = group;
        if (group.length) {
          this.formData.groupSelected = group[0].id;
          this.clientList = group[0].client;
        }
      }
    },

    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.page = 1;
          this.getUnDeliveryList();
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },

    resetForm(formName) {
      this.$refs[formName].resetFields();
      this.formData.show3Weeks = true;
      this.formData.onlyShowStock = false;
      this.formData.from = 1;
      this.$refs['clientsearch'].value = null
      if (this.group.length) {
        this.formData.groupSelected = this.group[0].id;
      }
    },

    handleClientSearch({ label, value }) {
      this.formData.clientSelected = value;
      this.$nextTick(() => {
        this.submitForm('ruleForm')
      })
    }
  },
};
</script>

<style>
html {
  background-color: #f2f3f7;
}
</style>
  
<style scoped>
.un-delivery {
  width: 1300px;
  margin: 0 auto;
  margin-bottom: 30px;
}

.form-item-wrap {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.form-item-wrap .el-form-item {
  float: 1;
  /* width: 33.3%; */
  width: 400px;
  /* background-color: cyan; */
}

.total-line {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: white;
  height: 48px;
  line-height: 48px;
  padding: 0 20px;
  font-size: 20px;
  border-radius: 5px;
  margin-top: 16px;
  margin-bottom: 14px;
  color: #d60010;
}

.inquiry-result {
  padding: 24px 20px;
  background-color: white;
  border-radius: 10px;
  box-shadow: 5px 10px 15px rgb(00, 00, 00, 0.1);
}

.inquiry-title {
  display: flex;
  flex-direction: row;
  background-color: #d60010;
  color: white;
  font-size: 14px;
  font-weight: bold;
  height: 26px;
  line-height: 26px;
  border-left: 1px solid #d60010;
}

.inquiry-title>div {
  padding-left: 5px;
  box-sizing: border-box;
}

.inquiry-item {
  display: flex;
  flex-direction: row;
  border: 1px solid #cdcdcf;
  border-top: none;
  color: #191919;
  min-height: 16px;
  padding: 6px 0;
  line-height: 1.4;
  font-size: 12px;
  align-items: center;
}

.inquiry-item.odd {
  background-color: #e4e5e9;
  display: flex;
  align-items: center;
}

.inquiry-item>div {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  padding-left: 5px;
  word-wrap: break-word;
  word-break: break-all;
  box-sizing: border-box;
}

.inquiry-item.odd {
  background-color: #e4e5e9;
}

.title-client,
.item-client {
  width: 64px;
}

.title-vender,
.item-vender {
  width: 99px;
}

.title-clientpn,
.item-clientpn {
  width: 101px;
}

.title-po,
.item-po {
  width: 100px;
}

.title-due-date,
.item-due-date {
  width: 90px;
}

.title-bal,
.item-bal {
  width: 100px;
}

.title-from,
.item-from {
  width: 78px;
}

.title-sr,
.item-sr {
  width: 120px;
}

.title-in,
.item-in {
  width: 86px;
}

.title-d16-30,
.item-d16-30 {
  width: 76px;
}

.title-d30,
.item-d30 {
  width: 86px;
}

.title-mark,
.item-mark {
  width: 140px;
}

.el-form-item {
  margin-bottom: 8px;
}
.form-item-wrap {
  height: 40px;
  line-height: 40px;
}
::v-deep .el-input--suffix .el-input__inner {
  height: 30px;
  line-height: 30px;
}
::v-deep .topform .form .form-item-wrap .el-form-item {
  height: 30px;
  line-height: 30px;
}
::v-deep .el-form-item__label {
  padding: 0;
}

.curosr {
  cursor: pointer;
  user-select: none;
}
</style>
<style>
.tooltip-width {
  max-width: 400px;
}
::v-deep .el-form-item__label {
  padding: 0;
}
</style>
  
