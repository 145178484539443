import axios from "axios";
import Cookies from "js-cookie";
import { Loading, Message } from "element-ui";
import NProgress from "nprogress";
import "nprogress/nprogress.css";
NProgress.configure({ minimum: 0.1, easing: "linear", speed: 500 });
const http = axios.create({
  timeout: 6000,
  baseURL: process.env.VUE_APP_BASEURL,
});

let httpCode = {
  400: "请求参数错误",
  401: "权限不足",
  403: "服务器拒绝本次访问",
  404: "请求资源未找到",
  500: "内部服务器错误",
  501: "服务器不支持该请求中使用的方法",
  502: "网关错误",
  504: "网关超时",
};

http.interceptors.request.use(
  (config) => {
    config.headers = {
      Authorization: Cookies.get("token"),
      "Content-Type": "application/json",
      ...config.headers,
    };

    NProgress.start();
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

http.interceptors.response.use(
  (response) => {
    NProgress.done();
    if (process.env.VUE_APP_ENV === "development") {
      response.data = {
        status: 0,
        data: response.data,
        message: "success",
      };
    }
    const { data, message, status } = response.data;
    let ok = false;
    // 请求成功
    if (response.status == "200") {
      if (status === 0) {
        // 正常返回
        ok = true;
      } else {
        Message({
          type: "error",
          message,
        });
        ok = false;
      }
    }
    return Promise.resolve({
      ok,
      data,
      message,
    });
  },
  (error) => {
    NProgress.done();
    if (error.response) {
      let tips =
        error.response.status in httpCode
          ? httpCode[error.response.status]
          : error.response.data.message;
      Message({
        message: tips,
        type: "error",
      });
      return Promise.reject({
        ok: false,
        message: tips,
        err: error.response,
      });
    } else {
      Message({
        message: "请求超时，请刷新重试",
        type: "error",
      });
      return Promise.reject({
        ok: false,
        message: "请求超时，请刷新重试",
        err: error.response,
      });
    }
  }
);

export { http };
