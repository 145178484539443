<template>
  <div class="putcontainer">
    <el-collapse-transition>
      <div v-show="boxshow">
        <slot />
      </div>
    </el-collapse-transition>
    <img
      v-show="boxshow"
      src="@/static/oem-v2/detailReport/putAway.png"
      alt=""
      class="pull-updown"
      @click="putAway"
    >
    <img
      v-show="!boxshow"
      src="@/static/oem-v2/detailReport/pulldown.png"
      alt=""
      class="pull-updown"
      @click="putAway"
    >
  </div>
</template>

<script>
export default {
  props: {
    defaultShow: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      boxshow: this.defaultShow,
    }
  },
  methods: {
    putAway() {
      this.boxshow = !this.boxshow
    }
  }
}
</script>

<style lang="scss" scoped>
.putcontainer {
  width: 100%;
  background-color: #fff;
  margin-top: 14px;
  border-radius: 10px;
  box-shadow: 5px 10px 15px rgba(00, 00, 00, 0.1);
}

.mybox-enter-active,
.mybox-leave-active {
  opacity: 1;
  max-height: 380px;
  transition: max-height 1s;
}

.mybox-enter,
.mybox-leave-to {
  opacity: 0;
  max-height: 0;
}

.pull-updown {
  display: block;
  margin: auto;
  cursor: pointer;
}
</style>