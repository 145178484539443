<template>
  <div class="home">
    <router-view />
  </div>
</template>

<script>
import { judgmentMobile } from "@/utils/isMobile";
export default {
  beforeMount() {
    if (judgmentMobile()) this.$router.push("mobileLogin");
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
$background-color: #d70010;
.home {
  width: 100%;
  background-color: #fff;
  margin: auto;
  .container {
    width: 1300px;
    margin: auto;
  }
}
</style>
