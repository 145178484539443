<template>
  <div class="topbar">
    <div class="navbar">
      <img
        src="@/static/oem-v2/navbar/logo.png"
        alt=""
      >
      <router-link
        v-for="(item, index) in navbarList"
        :key="index"
        class="aitem"
        :to="item.url"
        @click.native="changeItem(index)"
      >
        {{ item.name }}
      </router-link>
      <ElDropdown
        class="dropdown"
        @command="clickDropItem"
      >
        <span
          class="el-dropdown-link"
          @click="modifyAccount"
        >
          Modify My Profile <i class="el-icon-arrow-down" /> 
        </span>
        <span style="margin-left: 30px;">{{ $store.state.updateTime }}</span>
        <template #dropdown>
          <ElDropdownMenu>
            <ElDropdownItem command="changePassword">
              <span class="item">Change Password</span><i class="el-icon-lock" />
            </ElDropdownItem>
            <ElDropdownItem command="logout">
              <span class="item">Log out</span><i class="el-icon-switch-button" />
            </ElDropdownItem>
          </ElDropdownMenu>
        </template>
      </ElDropdown>
    </div>
    <div class="title">
      <div
        v-for="(v, i) in titleList"
        :key="i"
        class="title-item"
      >
        {{ v }}
      </div>
    </div>
  </div>
</template>

<script>
import Cookies from "js-cookie";
export default {
  data() {
    return {
      navbarList: [
        { id: 0, name: "Detail Report", url: "/detailReport" },
        { id: 1, name: "Summary Report", url: "/summaryReport" },
        { id: 2, name: "UN-Delivery", url: "/unDelivery" },
        { id: 3, name: "Ship & Plan", url: "/shipPlan" },
      ],
      titleList: [],
    };
  },
  created() {
    if (this.$route.path == "/detailReport") {
      this.titleList = ["Order Status inquiry", "Detail Report"];
    }
    if (this.$route.path == "/summaryReport") {
      this.titleList = ["Order Status inquiry", "Summary Report"];
    }
    if (this.$route.path == "/shipPlan") {
      this.titleList = ["Order Status inquiry", "Delivery info"];
    }
    if (this.$route.path == "/unDelivery") {
      this.titleList = ["Order Status inquiry", "UN-Delivery List"];
    }
    if (this.$route.path == "/changePassword") {
      this.titleList = ["Change Password"];
    }
    if (this.$route.path == "/modifyAccount") {
      this.titleList = ["Modify My Profile"];
    }
  },
  methods: {
    changeItem(val) {
      if (val == 0) {
        this.titleList = ["Order Status inquiry", "Detail Report"];
      }
      if (val == 1) {
        this.titleList = ["Order Status inquiry", "Summary Report"];
      }
      if (val == 2) {
        this.titleList = ["Order Status inquiry", "UN-Delivery List"];
      }
      if (val == 3) {
        this.titleList = ["Order Status inquiry", "Delivery info"];
      }
    },
    // 下拉菜单
    clickDropItem(item) {
      if (item == "changePassword") {
        this.$router.push({ name: "changePassword" });
        this.titleList = ["Change Password"];
      }
      if (item == "logout") {
        Cookies.remove("token");
        window.localStorage.removeItem("userInfo");
        this.$router.push({ name: "login" });
      }
    },
    // 修改账户
    modifyAccount() {
      this.$router.push({ name: "modifyAccount" });
      this.titleList = ["Modify My Profile"];
    },
  },
};
</script>

<style lang="scss" scoped>
$background-color: #d70010;

.navbar {
  display: flex;
  align-items: center;
  padding: 30px 0;
  position: relative;

  .dropdown {
    float: right;
    position: absolute;
    right: 0;
  }

  a {
    display: block;
  }

  img {
    width: 66px;
    height: 39px;
    margin-right: 70px;
  }

  .aitem {
    color: #9c9db0;
    margin-right: 55px;
    font-size: 16px;
    text-decoration: none;
  }

  .router-link-active {
    background-color: #fff;
    font-size: 18px;
    color: #000;
    border-radius: 20px;
    padding: 8px 20px;
    box-shadow: 2px 5px 10px rgba(00, 00, 00, 0.1);
  }

  .item {
    display: block;
    width: 120px;
  }
}

.title {
  background-color: $background-color;
  font-size: 14px;
  color: #fff;
  display: flex;
  align-items: center;
  padding: 12px 0;
  border-radius: 10px;
  box-shadow: 5px 12px 22px rgba(215, 0, 16, 0.2);

  .title-item:nth-child(1) {
    margin: 0 42px 0 12px;
  }
}

.el-dropdown-link:hover {
  cursor: pointer;
}
</style>
