<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
export default {};
</script>
<style lang="scss">
#nprogress .bar {
  background: #d60010 !important;
  height: 4px !important;
}
</style>
