<template>
  <div class="login-page">
    <div class="login-box">
      <div class="login-left">
        <div class="top">
          <img src="@/assets/login/air-single.png">
        </div>
        <div class="center">
          <text class="left-text1">
            Service Change Your Business
          </text>
          <text class="left-text2">
            LET’S ET YOU IN!
          </text>
          <text class="left-text3">
            24 Hour Service
          </text>
          <text class="left-text4">
            24 Tracking your parts anytime anywhere
          </text>
        </div>
        <div class="bottom">
          <img
            src="@/assets/login/login.png"
            alt=""
          >
        </div>
        <div class="side">
          <img
            src="@/assets/login/envelope.png"
            alt=""
          >
        </div>
      </div>
      <div class="login-right">
        <LoginForm />
        <div
          class="visit-previous"
          @click="handleNavPrevious"
        >
          Visit previous version website
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LoginForm from "@/components/userRelevant/LoginForm";
import { judgmentMobile } from "@/utils/isMobile";
export default {
  components: {
    LoginForm,
  },
  data() {
    return {};
  },
  beforeMount() {
    if (judgmentMobile()) this.$router.push("mobileLogin");
  },
  methods: {
    handleNavPrevious() {
      window.open("http://previous-oem.ep-ep.com", "_blank")
    }
  },
};
</script>

<style lang="scss" scoped>
.login-page {
  max-width: 1920px;
  height: 100vh;
  background-color: #f2f3f7;
  position: relative;
  font-family: "PingFang";
  margin: 0 auto;
  .login-box {
    box-sizing: border-box;
    width: 856px;
    height: 470px;
    border-radius: 30px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    box-shadow: 0px 0px 10px 5px #eed9df;
    display: flex;
    .login-left {
      width: 50%;
      padding: 22px 0 0 40px;
      background-color: #d70001;
      border-top-left-radius: 30px;
      border-bottom-left-radius: 30px;
      color: #fff;
      .top {
        margin-left: -35px;
      }
      .center {
        margin-top: -70px;
        display: flex;
        flex-direction: column;
        font-size: 14px;
        .left-text2 {
          padding: 10px 0;
          font-size: 26px;
        }
      }
      .bottom {
        width: 100%;
        margin-top: 20px;
        margin-left: -40px;
        img {
          margin: 0 auto;
        }
      }
      .side {
        position: absolute;
        top: -90px;
        right: -50px;
      }
    }
    .login-right {
      width: 50%;
      border-top-right-radius: 30px;
      border-bottom-right-radius: 30px;
      background-color: #f2f3f7;
    }
  }
}

.visit-previous {
  text-align: center;
  cursor: pointer;
}
</style>
