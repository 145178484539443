import Vue from "vue";
import VueRouter from "vue-router";
import Cookies from "js-cookie";
import HomeView from "../views/HomeView.vue";
import unDelivery from "../views/unDelivery.vue";
import login from "@/views/user/login.vue";

Vue.use(VueRouter);

// 解决vue-router 相同路径跳转报错问题
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

const routes = [
  {
    path: "/",
    component: () => import("@/views/HomeView.vue"),
    redirect: '/detailReport',
    children: [
      {
        path: "/detailReport",
        name: "detailReport",
        component: () => import("@/views/detailReport.vue"),
      },
      {
        path: "/summaryReport",
        name: "summaryReport",
        component: () => import("@/views/summaryReport.vue"),
      },
      {
        path: "/shipPlan",
        name: "shipPlan",
        component: () => import("@/views/shipPlan.vue"),
      },
      {
        path: "/unDelivery",
        name: "unDelivery",
        component: () => import("@/views/unDelivery.vue"),
      },
      {
        path: "/changePassword",
        name: "changePassword",
        component: () => import("@/views/user/changePassword.vue"),
        meta: { title: "changePassword" },
      },
      {
        path: "/modifyAccount",
        name: "modifyAccount",
        component: () => import("@/views/user/modifyAccount.vue"),
        meta: { title: "modifyAccount" },
      }
    ],
  },
  {
    path: "/un-delivery",
    name: "un-delivery",
    component: unDelivery,
  },
  {
    path: "/login",
    name: "login",
    component: login,
    meta: { title: "Login" },
  },
  {
    path: "/mobileLogin",
    name: "mobileLogin",
    component: () => import("@/views/mobile/login"),
    meta: { title: "mobileLogin" },
  },
  {
    path: "/mobileHome",
    name: "mobileHome",
    component: () => import("@/views/mobile/home"),
    meta: { title: "mobileHome" },
  },
  {
    path: "/modify",
    name: "modify",
    component: () => import("@/views/mobile/modify"),
    meta: { title: "mobileHome" },
  },
];

const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  const token = Cookies.get("token");
  if (!token) {
    if (to.path == "/login" || to.path == "/mobileLogin") return next();
    return next("/login");
  } else if (to.path == "/login") {
    return next();
  } 
  next();
});

export default router;
