import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "normalize.css";
import ElementUI from "element-ui";
// 自定义主题
import "./theme/index.css";
import locale from "element-ui/lib/locale/lang/en";
// import "default-passive-events";
// import 'element-ui/lib/theme-chalk/index.css'

import VueVirtualScroller from "vue-virtual-scroller";
import "vue-virtual-scroller/dist/vue-virtual-scroller.css";
import gePageTop from "./utils/pageTop";


Vue.use(VueVirtualScroller);

Vue.use(ElementUI, { locale });

Vue.config.productionTip = false;
Vue.prototype.$getImg = function (src) {
  let defaultImg = "";
  if (src == null || src == "") {
    defaultImg = require("@/static/oem-v2/detailReport/default.jpg");
  } else {
    defaultImg = process.env.VUE_APP_IMGURL + src;
  }
  return defaultImg;
};

// 在原型上挂载返回顶部的方法
Vue.prototype.$goPageTop = gePageTop;
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
