<template>
  <el-select
    v-model="value"
    filterable
    remote
    clearable
    placeholder="please enter"
    :remote-method="searchClient"
    :loading="loading"
    :style="styleStr"
    @clear="clearValue"
  >
    <el-option
      v-for="item in options"
      :key="item.value"
      :label="item.label"
      :value="item.id"
    />
  </el-select>
</template>  

<script>
import { searchClient } from '@/api/index'
export default {
    props: {
      styleStr: {
        type: String,
        default: ''
      },
      initValue: {
        type: Number,
        default: null
      },
      initOptions: {
        type: Array,
        default: () => {
          return []
        }
      }
    },
    data() {
      return {
        options: this.initOptions,
        value: this.initValue,
        loading: false
      }
    },
    watch: {
      value(val) {
          let label = ''
          this.options.forEach(ele => {
            if(ele.id === val) {
              label = ele.label
            }
          })

          this.$emit("valueChange", {
          value: this.value,
          label
         }) 
      }
    },
    methods: {
      async searchClientmsg(inputvalue) {
        const {ok, data} = await searchClient({ keyword:inputvalue });
        this.loading = false;
        if(ok) {
          this.options = data
          return 
        }
        this.options = []
      },
      searchClient(keyword) {
        this.searchClientmsg(keyword)
      },

      // 清空
      clearValue() {
        this.searchClientmsg("")
      }
    }
  }
</script>
<style scoped>
</style>